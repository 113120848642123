import firebase from "../firebase";

const db = firebase.collection("/users");

class UsersDataService {
    getAll() {
        return db;
    }

    create(user) {
        return db.add(user);
    }

    read(id) {
        return db.doc(id).get();
    };

    update(id, value) {
        return db.doc(id).update(value);
    }

    delete(id) {
        return db.doc(id).delete();
    }
}

export default new UsersDataService();